<template>
  <div class="text">
    <div class="nav">
      <ul>
        <button class="btn language" :style="filterStyle('en')" v-on:click="changeLang('en')">EN</button>
        <button class="btn language" :style="filterStyle('ru')" v-on:click="changeLang('ru')">RU</button>
        <button class="btn language" :style="filterStyle('ua')" v-on:click="changeLang('ua')">UA</button>
      </ul>
      <hr>
      <h1 class="centerText">{{ $t('noWar') }}</h1>
    </div>
    <h2>{{ $t('sinceMomentText') }}</h2>
  </div>
  <div class="timer">
    <table cellspacing="0">
      <tr>
        <th>{{ this.dd }}</th><th>:</th><th>{{ this.hh }}</th><th>:</th><th>{{ this.mm }}</th><th>:</th><th>{{this.ss}}</th>
      </tr>
      <tr>
        <td>{{ $tc('days', this.dd, 4) }}</td><td></td><td>{{ $tc('hours', this.hh_int, 4) }}</td><td></td><td>{{ $tc('minutes', this.mm_int, 4) }}</td><td></td><td>{{ $tc('seconds', this.ss_int, 4) }}</td>
      </tr>
    </table>
  </div>
</template>


<script>
import moment from 'moment'

export default {
  name: 'HelloWorld', 
  //metaInfo: {
  //    title: '#NoWar'
  //},
  data() {
    var msg = 'Kek';
    var dd=0;
    var hh=0;
    var mm=0;
    var ss=0;
    var hh_int=0;
    var mm_int=0;
    var ss_int=0;
    return {
      msg,
      dd,
      hh,
      mm,
      ss,
      hh_int,
      mm_int,
      ss_int

    }
  },
  mounted() {
    //document.title = "#NoWar"; 
    this.getData(),
    this.timer(),
    this.setTitle(),
    this.setLang()
  },
  methods: {
    filterStyle(lang) {
      if (this.$i18n.locale===lang) {
        return {
          'text-decoration': 'underline'
        };
      } else {
        return '';
      }
    },
    getData() {
      var then  = "24/02/2022 03:00:00";
      //var now = "05/04/2022 23:58:30";
      var dt = new Date();
      var now2 = `${
          dt.getUTCDate().toString().padStart(2, '0')}/${
          (dt.getUTCMonth()+1).toString().padStart(2, '0')}/${
          dt.getUTCFullYear().toString().padStart(4, '0')} ${
          dt.getUTCHours().toString().padStart(2, '0')}:${
          dt.getUTCMinutes().toString().padStart(2, '0')}:${
          dt.getUTCSeconds().toString().padStart(2, '0')}`;
      //console.log('now2 ='+now2+'k');
      var ms = moment(now2,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
      var d = moment.duration(ms);
      var s = Math.floor(d.asDays()) + moment.utc(ms).format(":HH:mm:ss");
      this.msg = s;
      this.dd = Math.floor(d.asDays());
      this.hh = moment.utc(ms).format("HH");
      this.mm = moment.utc(ms).format("mm");
      this.ss = moment.utc(ms).format("ss");
      this.hh_int = parseInt(this.hh);
      this.mm_int = parseInt(this.mm);
      this.ss_int = parseInt(this.ss);
      //console.log(this.dd+':'+this.hh+':'+this.mm+':'+this.ss);
      //return this.msg;
      //console.log(this.$i18n.locale);
      //console.log(this.$i18n.availableLocales)
      
    },
    
    timer() {
      setInterval(this.getData, 1000);
    },
    
    changeLang(lang) {
      this.$i18n.locale=lang;
      this.setTitle()
      this.$cookies.set('lang', lang); 
      //this.$cookies.remove('lang');
   },

   setTitle() {
      if (this.$i18n.locale==='en')
      {
        document.title = "#NoWar";
      }
      else if (this.$i18n.locale==='ru') 
      {
        document.title = "#НетВойне";
      }
      else {
        document.title = "#НіВійні";
      }
    },

    setLang() {
      if (this.$cookies.isKey('lang')) {
        //console.log('cookies есть');
        this.$i18n.locale = this.$cookies.get('lang')
      }
      else {
        //console.log('cookies нет');
        let language = navigator.language;
        //console.log(navigator.language);
        
        if (language === 'ru-RU')
        {
          this.$i18n.locale='ru';
          this.setTitle()
        } 
        else if (language === 'uk-UA') {
          this.$i18n.locale='ua';
          this.setTitle()
        }
        else {
          this.$i18n.locale = 'en';
          this.setTitle()
        }
      }
      
    }

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body
{
  background: #131313;
  color: #ffffff;
}

.text {
  font-family: Raleway, sans-serif;
  font-weight: 500;
}

.timer {
  font-family: Rubik, sans-serif;
  font-weight: 400;
}

table {
  text-align: center;
  margin: auto;
}

th { 
  font-size: 3em;
  font-weight: 400;

}

td {
  font-size: 1em;
}


hr {
  width: 100%;
  height: 1px;
  background-color: #cecece;
  border: 0;
  margin: 1em 0;
}

.nav p {
  margin: 1em 0;
  padding: 0.2em 0.5em 0.2em 1.5em;
  float: left;
  font: Rubik, sans-serif;
}

.nav ul {
  list-style-type: none;

  padding: 0;
  margin-right: 1em;
  float: right;
  
}

.nav button {
  display: inline-block;
  font-size: 1em;
  padding: 0.2em 0.5em;
  margin: 0 0.5em;
  border: none;
  background-color: inherit;
  color: #ffffff;
  cursor: pointer;
  
}

.btn:hover {
  background: #ffffff;
  color: #131313;
}

h1 {
  text-align: center;
  margin: 0.5em 0;
}

h2 {
  margin: 0.5em 0;
}

</style>