import { createApp } from 'vue'
import App from './App.vue'
import i18n from'./locales/i18n'
import VueCookies from 'vue-cookies'

createApp(App)
    .use(i18n)
    .use(VueCookies, {expire: '30d'})
    .mount('#app')
    
