import { createI18n } from 'vue-i18n/index';

import en from './en.json';
import ru from './ru.json';
import ua from './ua.json';

var i18n = createI18n({
    //locale: "en",
    pluralizationRules: {

        'ru': function(choice, choicesLength) {

            if (choice === 1) {
            return 1;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 3) {
            return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
            return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        },

        'ua': function(choice, choicesLength) {

            if (choice === 1) {
            return 1;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 3) {
            return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
            return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        },

        'en': function(choice, choicesLength) {
            if (choicesLength === 2) {
                if (choice === 1) {
                    return 0
                }
                else
                    return 1;
            }
            
        }
    },
    messages: {
        en: en,
        ru: ru,
        ua: ua
    }
})

export default i18n;